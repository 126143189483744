import { Alert } from 'antd';
import LabelRow from 'components/common/LabelRow';
import { FlexBox } from 'components/Helpers';
import { useMemo } from 'react';
import { validateJsonPropertyAndMaskSecrets } from 'utlis/validationUtil';
import {
  oidcConfigDeviceProperty,
  OIDCConfigSchema,
} from './UserManagerOIDCConfigForm';

interface Props {
  properties: Record<string, string>;
}

const UserManagerOIDCConfigSummary: React.FC<Props> = ({ properties }) => {
  const [oidcConfig, validationError] = useMemo(
    () =>
      validateJsonPropertyAndMaskSecrets(
        properties[oidcConfigDeviceProperty],
        OIDCConfigSchema,
      ),
    [properties],
  );

  return (
    <FlexBox direction="column">
      {validationError !== null && (
        <Alert
          message="Fehler"
          description={validationError}
          type="error"
          showIcon
        />
      )}
      <FlexBox direction="column">
        <span style={{ marginBottom: 5 }}>OIDC Auth</span>
        {oidcConfig !== null ? (
          <>
            <LabelRow label="Client ID" value={oidcConfig.clientId ?? ''} />
            <LabelRow
              label="Client Secret"
              value={oidcConfig.clientSecret ?? ''}
            />
            <LabelRow
              label="Client Auth Method"
              value={oidcConfig.clientAuthMethod ?? '-'}
            />
            <LabelRow
              label="Discovery URI"
              value={oidcConfig.discoveryUri ?? ''}
            />
            <LabelRow
              label="Scopes"
              value={oidcConfig.scopes?.join(', ') ?? ''}
            />
            <LabelRow
              label="Refresh Token Rotation"
              value={
                oidcConfig.isRefreshTokenRotationEnabled === true
                  ? 'Ja'
                  : 'Nein' // default
              }
            />
            <LabelRow
              label="Refresh Token Expiration Duration"
              value={oidcConfig.refreshTokenExpirationDuration ?? '-'}
            />
            <LabelRow
              label="End Session on Logout"
              value={
                oidcConfig.endSessionOnLogout === true ? 'Ja' : 'Nein' // default
              }
            />
            <LabelRow
              label="Skip E-Mail Verified Check"
              value={
                oidcConfig.skipEmailVerifiedCheck === true ? 'Ja' : 'Nein' // default
              }
            />
            <LabelRow
              label="Use Refresh Tokens"
              value={
                oidcConfig.useRefreshTokens === false ? 'Nein' : 'Ja' // default
              }
            />
            <LabelRow
              label="Force Re-Authenticate"
              value={
                oidcConfig.forceReAuthenticate === true ? 'Ja' : 'Nein' // default
              }
            />
            <LabelRow
              label="Response Mode"
              value={oidcConfig.responseMode ?? '-'}
            />
            <LabelRow
              label="Additional Authorization Parameters"
              value={
                oidcConfig.additionalAuthorizationParameters
                  ? JSON.stringify(oidcConfig.additionalAuthorizationParameters)
                  : '-'
              }
            />
          </>
        ) : (
          <Alert
            message="Fehler"
            description="Fehler beim Laden der OIDC-Konfiguration"
            type="error"
            showIcon
          />
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default UserManagerOIDCConfigSummary;
